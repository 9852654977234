<template>
  <div class="preloader">
    <div class="loader"></div>
  </div>
</template>

<script>
export default {
  name: "pre-loader"
};
</script>

<style lang="scss" scoped>
.preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999999;
  background-color: #fff;
  opacity: 1;
  transition: opacity 0.3s ease-out;

  &.page-loaded {
    opacity: 0;
  }
}

.loader {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -13px;
  margin-left: -13px;
  border-radius: 60px;
  animation: loader 0.8s linear infinite;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
    border: 4px solid #8bc152;
    border-left-color: transparent;
  }
  50% {
    transform: rotate(180deg);
    border: 4px solid #ff8000;
    border-left-color: transparent;
  }
  100% {
    transform: rotate(360deg);
    border: 4px solid #8bc152;
    border-left-color: transparent;
  }
}
</style>
