<template>
  <div
    id="tpl-events"
    class="template-events-2"
    v-if="candidate.events.length > 0"
  >
    <b-container>
      <div>
        <h3 class="mb-5">Upcoming Events</h3>
      </div>
      <div>
        <b-card-group deck>
          <b-card no-body class="overflow-hidden" v-for="(event, i) in candidate.events" :key="i">
            <b-row no-gutters>
              <b-col md="3" class="event-date">
                <b-card-body
                  class="text-md-center"
                  :title="getEventMonth(event)"
                  :sub-title="getEventDay(event)"
                ></b-card-body>
              </b-col>
              <b-col md="9" class="event-details">
                <b-card-body :title="event.title">
                  <b-card-text>
                    <time>{{ event.dateTimeText }}</time>
                  </b-card-text>
                  <b-card-text>{{ event.description }} </b-card-text>
                  <b-card-text>
                    <a :href="event.ctaLink">{{ event.ctaText }}</a>
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </b-card-group>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "Events"
    };
  },
  computed: {
    candidate() {
      return this.$store.getters["candidates/candidate"];
    }
  },
  methods:{
    getEventMonth(event){
      var dates= new Date(event.dateTimeText).toString().split(" ")     
      return dates[1]
    },
    getEventDay(event){
      var dates= new Date(event.dateTimeText).toString().split(" ")     
      return dates[2]
    }
  }
};
</script>

<style lang="scss" scoped></style>
