<template>
  <div class="container">
    <header class="jumbotron">
      <h1>{{content}}</h1>
    </header>
  </div>
</template>

<script>

export default {
  name: 'PageNotFound',
  data() {
    return {
      content: 'Page not found.'
    };
  }  
};
</script>