<template>
  <pre-loader v-if="isLoading"></pre-loader>
  <div v-else class="admin-preview">
    <div class="admin-preview-banner">
      <font-awesome-icon :icon="['far', 'eye']"/>
      This is a Preview of your Candidate Website
      <span class="mx-4">|</span>
      <b-link @click="closeWindow">Close Preview</b-link>
    </div>
    <div v-if="!noCandidate" class="admin-preview-template">
      <template-one v-if="candidate.profileTheme === 1"></template-one>
      <template-two v-if="candidate.profileTheme === 2"></template-two>
      <template-three v-if="candidate.profileTheme === 3"></template-three>
      <template-four v-if="candidate.profileTheme === 4"></template-four>
      <template-five v-if="candidate.profileTheme === 5"></template-five>
      <template-six v-if="candidate.profileTheme === 6"></template-six>
    </div>
    <not-found v-else></not-found>
  </div>
</template>

<script>
import TemplateOne from "@/views/public/Template1";
import TemplateTwo from "@/views/public/Template2";
import TemplateThree from "@/views/public/Template3";
  import TemplateFour from "@/views/public/Template4";
  import TemplateFive from "@/views/public/Template5";
  import TemplateSix from "@/views/public/Template6";
import NotFound from "@/views/404";
import PreLoader from "@/components/common/PreLoader";

export default {
  name: "candidate",
  data: () => ({
    candidate: null,
    noCandidate: false,
    isLoading: true,
  }),
  components: {
    TemplateOne,
    TemplateTwo,
    TemplateThree,
    TemplateFour,
    TemplateFive,
    TemplateSix,
    NotFound,
    PreLoader,
  },
  // beforeMount() {
  //   this.$store
  //     .dispatch("candidates/getCandidatePreviewByName", this.$route.params.candidate)
  //     .then(data => {
  //       this.$store.commit("candidates/SET_CANDIDATE", data.data);
  //       this.candidate = data.data;
  //     });
  // },
  // mounted() {
  //   let vm = this;
  //   vm.isLoading = false;

  //   setTimeout(() => {
  //     vm.noCandidate = vm.candidate ? false : true;
  //   }, 1000);
  // }
  async created() {
    let response = await this.$store.dispatch(
      "candidates/getCandidatePreviewByName",
      this.$route.params.candidate
    );
    this.$store.commit("candidates/SET_CANDIDATE", response.data);
    this.isLoading = false;
    this.candidate = response.data;
    this.noCandidate = this.candidate ? false : true;
    document.title = "Preview - WGAW Candidate"
  },
   methods: {
     closeWindow() {
       window.close();
     }
   }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/admin/_base.scss";

.admin-preview {

  .admin-preview-banner {
    position: fixed;
    z-index: 2000;
    top: 0;
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center; 
    justify-content: center;    
    text-align: center;
    font-family: $montserrat;
    font-weight: 600;
    font-size: 24px;
    color: $black;
    background-color: #ffc107;
    //background: linear-gradient(45deg, rgba(33, 150, 243, 1) 1%, rgba(76, 175, 80, 1) 100%);

    svg {
      margin-right: 5px;
    }

    a {
      color: $black;
      text-decoration: underline;
    }
  }

  .admin-preview-template {
    margin-top: 80px;
  }

  @media (max-width: $sm-width) {
    .admin-preview-banner {
      font-size: 16px;

      svg {
        display: none;
      }
    }
  }
}
</style>
