<template>
  <div class="template-2">
    <TemplateHeader />
    <TemplateHeroHeader />
    <TemplateOpenContent v-if="candidate.openContent" />
    <TemplateFeatureText v-if="candidate.featureText || candidate.featureTitle" />
    <TemplateMediaBlock />
    <div class="template-endorsements">
      <TemplateEndorsements v-if="showEndorsements" />
      <TemplateEndorsementList />
    </div>
    <TemplateEvents2 />
    <TemplateContactInfo2 />
  </div>
</template>

<script>
import TemplateHeader from "@/components/public/Header.vue";
import TemplateHeroHeader from "@/components/public/HeroHeader.vue";
import TemplateOpenContent from "@/components/public/OpenContent.vue";
import TemplateFeatureText from "@/components/public/FeatureText.vue";
import TemplateMediaBlock from "@/components/public/MediaBlock.vue";
import TemplateEndorsements from "@/components/public/Endorsements.vue";
import TemplateEvents2 from "@/components/public/Events2.vue";
import TemplateContactInfo2 from "@/components/public/ContactInfo2.vue";
import TemplateEndorsementList from "@/components/public/EndorsementList.vue";

import { ThemeColorLoader } from "@/services/helpers";

export default {
  data() {
    return {
      title: "Template 2",
    };
  },
  components: {
    TemplateHeader,
    TemplateHeroHeader,
    TemplateOpenContent,
    TemplateFeatureText,
    TemplateMediaBlock,
    TemplateEndorsements,
    TemplateEvents2,
    TemplateContactInfo2,
    TemplateEndorsementList
  },
  computed: {
    candidate() {
      return this.$store.getters["candidates/candidate"];
    },
    showEndorsements() {
      return this.$store.getters["candidates/showEndorsementMenuItem"];
    },
  },
  mounted() {
    ThemeColorLoader(this.candidate.profileColor);

    // Removing Null link from event cards ---------------------//

    let eventLinks = document.querySelectorAll(".template-events-2 .card a");

    for (let i = 0; i < eventLinks.length; i++) {
      let linkText = eventLinks[i].textContent;

      if (linkText === "null") {
        eventLinks[i].classList.add("d-none");
      }
    }
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/public/theme/_base.scss";
@import "@/assets/styles/public/theme/template-2.scss";
</style>
